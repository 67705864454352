<template>
  <div class="page">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :postData="postData"
      methods="post"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="font-size: 14px; margin-right: 10px">时间查询：</div>
          <v-date-picker
            v-model="datetimerange"
            type="datetimerange"
            clearable
            @change="changeDate"
          ></v-date-picker>
        </div>
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  addOrUpVisPerInfoUrl,
  deleteVisPerInfoUrl,
  getVisPerInfoUrl,
  importUrl,
  getSearchWordsUrl,
  updateBusinessInfoUrl,
  deleteBusinessIncomeUrl,
  getBusinessInfosUrl,
  deleteBusinessInfoUrl,
  getBusinessIncomeUrl,
  exportBusinessInfoUrl,
  exportBusinessIncomeUrl,
  exportBusinessRevenueUrl,
  deleteBusinessRevenueUrl,
  getBusinessRevenueUrl,
  sendSmsUrl,
  getBuildingInfoUrl,
  saveLastWordsUrl,
  getLastWordsUrl,
} from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "visitorsPage",
  data() {
    return {
      headers: [
        {
          prop: "companyName",
          label: "公司名称",
        },
        {
          prop: "",
          label: "走访人员",
        },
        {
          prop: "",
          label: "走访人手机号",
        },
        {
          prop: "imageUrl",
          label: "照片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "visitingAddress",
          label: "走访地点",
        },
        {
          prop: "createTime",
          label: "走访时间",
        },
      ],
      datetimerange: [],
      tableUrl: `/gateway/hc-arch/visiting/getRecInfoList`,
      postData: {
        visitingStartTime: null,
        visitingEndTime: null,
        taxpayerRegistrationNumber: null,
      },
    };
  },
  created() {
    this.postData.taxpayerRegistrationNumber =
      this.$route.query.taxpayerRegistrationNumber;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    changeDate() {
      if (this.datetimerange && this.datetimerange.length == 2) {
        this.postData.visitingStartTime =
          this.datetimerange[0].format("yyyy-MM-dd");
        this.postData.visitingEndTime =
          this.datetimerange[1].format("yyyy-MM-dd");
      } else {
        this.postData.visitingStartTime = null;
        this.postData.visitingEndTime = null;
      }
    },
    // 删除
    remove(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(
              `/gateway/hc-arch/visiting/deleteRecInfo`,
              this.$qs.stringify(params)
            )
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.page {
  height: 100%;
  box-sizing: border-box;
}
</style>
